// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  html and body styles are in the HTML document so they are available as soon as the document is
  received
*/

.header {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  padding: 1em 1.2em;
}

.header .header-wild-text {
    height: 1em;
  }

.header .header-lionbulb {
    width: 2em;
  }

.header h1 {
    margin: 0;
    flex-shrink: 0;
  }

main {
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/viewport.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,QAAQ;EACR,kBAAkB;AAcpB;;AAZE;IACE,WAAW;EACb;;AAEA;IACE,UAAU;EACZ;;AAEA;IACE,SAAS;IACT,cAAc;EAChB;;AAGF;AACA;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/*\n  html and body styles are in the HTML document so they are available as soon as the document is\n  received\n*/\n\n.header {\n  position: absolute;\n  top: 0px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 1em;\n  padding: 1em 1.2em;\n\n  & .header-wild-text {\n    height: 1em;\n  }\n\n  & .header-lionbulb {\n    width: 2em;\n  }\n\n  & h1 {\n    margin: 0;\n    flex-shrink: 0;\n  }\n}\n\nmain {\n}\n\n@keyframes fade {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
