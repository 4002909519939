import React from 'react';

import * as css from './PhoneFrame.module.css';

export const PhoneFrame: React.FC<{
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, className, style }) => (
  <div className={[css.phone, className].filter(Boolean).join(' ')} style={style}>
    <div className={css.phoneScreenGutter}>
      <div className={css.phoneScreen}>{children}</div>
    </div>
  </div>
);
