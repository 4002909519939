import React from 'react';
import { animated, useTransition } from '@react-spring/web';

import { useAnimatedRouteState } from '@wildidea/components/AnimatedRoutes/AnimatedRoutes';
import { useThemeAnimations, useThemeBreakpoints } from '@wildidea/components/Theme/Theme';
import { LinkButton } from '@wildidea/components/Button/LinkButton';

import { LionBulb } from '@components/LionBulb.js';
import { Idea, Wild, WildIdea } from '@components/WildIdeaText.js';
import { PhoneFrame } from '@components/PhoneFrame.js';

import onemapScreenshot from './img/onemap-mobile.png';
import commutablyScreenshot from './img/commutably-mobile.png';

import * as css from './HomeRoute.module.css';

const HomeRoute: React.FC<{}> = () => {
  const breakpoints = useThemeBreakpoints();

  const { active, onTransitionEnd } = useAnimatedRouteState();
  const { inPlace } = useThemeAnimations('Common');
  const splashTransition = useTransition(active, {
    ...inPlace,
    onRest: onTransitionEnd
  });
  const contentHeadingTransition = useTransition(active, inPlace);
  const contentLeftTransition = useTransition(active, inPlace);
  const contentRightTransition = useTransition(active && breakpoints.md, inPlace);

  return (
    <div className={css.layout}>
      {splashTransition((style, active) =>
        active ? (
          <animated.div style={style}>
            <div className={css.hook}>
              <h1 className={css.tagLine}>Inspiration can't be domesticated.</h1>
              {breakpoints.md ? (
                <WildIdea className={css.wildIdeaText} />
              ) : (
                <div className={css.wildIdeaText}>
                  <Wild />
                  <Idea />
                </div>
              )}
            </div>
            <div className={css.lionBulb}>
              <LionBulb aria-label="The Wild Idea logo: A stylized lion's head sprouting from the threads of a lightbulb" />
            </div>
          </animated.div>
        ) : null
      )}

      <div className={css.content}>
        {contentHeadingTransition((style, active) =>
          active ? (
            <animated.div style={style}>
              <h2>Apps</h2>
            </animated.div>
          ) : null
        )}

        <div className={css.contentRow}>
          {contentLeftTransition((style, active) =>
            active ? (
              <animated.div style={style}>
                <p>
                  You may have heard the expression: "Art from adversity". At
                  <WildIdea style={{ display: 'inline', height: '1em', margin: '0 0.4em' }} />
                  we believe that true creativity is always{' '}
                  <strong>inspired by need and sharpened by limitation</strong>. Great, innovative
                  solutions tend to have a few things in common:
                  <ul>
                    <li>They are driven by passion for the idea, not passion for a big payoff.</li>
                    <li>
                      They focus on solving <strong>one specific problem</strong> in a novel, better
                      way.
                    </li>
                    <li>
                      They are built in close cooperation with those who best understand the problem
                      and are actively using the solution.
                    </li>
                    <li>
                      They are <strong>never a sure thing</strong>.
                    </li>
                  </ul>
                </p>
                <p>
                  The internet may seem to be filling up with junk, but for every idea kept on life
                  support by advertising there is a brilliant idea that might never have been built.
                </p>
                <p>Until now.</p>

                <LinkButton
                  appearance="fill"
                  color="info"
                  href="mailto:consulting@wildidea.io"
                  className={css.hero}
                >
                  Let's make it happen.
                </LinkButton>

                <div className={css.appCard}>
                  <h3>
                    <a href="https://dev.onemap.earth/user/hiebj" target="_blank" rel="nofollow">
                      OneMap
                    </a>
                  </h3>
                  <p>
                    Under active development in collaboration with the{' '}
                    <a href="https://www.cmc.org/" target="_blank" rel="nofollow">
                      Colorado Mountain Club
                    </a>
                    , <strong>OneMap</strong> is a real-time satellite tracking and operations
                    dashboard for field teams, adventurers, enthusiasts, and outdoor organizations.
                    We render{' '}
                    <strong>
                      3D topographic and satellite maps overlaid with satellite tracking and
                      messages
                    </strong>{' '}
                    for Garmin devices, SPOT Personal Tracker and other realtime satellite trackers.
                  </p>
                  {!breakpoints.md ? (
                    <div className={css.mobileScreenshot}>
                      <img
                        src={onemapScreenshot}
                        className={css.screenshot}
                        style={{ objectPosition: 'center 20%' }}
                      />
                    </div>
                  ) : null}
                  <p>
                    In addition to detailed history for both individuals and organizations,{' '}
                    <strong>OneMap</strong> can follow multiple users at once in real time for field
                    operations. It can also display any currently-active trackers within an
                    organization.
                  </p>
                </div>

                <div className={css.appCard}>
                  <h3>
                    <a href="https://commutably.net" target="_blank" rel="nofollow">
                      Commutably
                    </a>
                  </h3>
                  <p>
                    When searching for a new residence, one of the most important factors is{' '}
                    <strong>drivability</strong>: how easy it is to get to the places you go most
                    often. It is also one of the hardest to determine.
                  </p>
                  {!breakpoints.md ? (
                    <div className={css.mobileScreenshot}>
                      <img src={commutablyScreenshot} className={css.screenshot} />
                    </div>
                  ) : null}

                  <p>
                    To solve this problem,
                    <WildIdea style={{ display: 'inline', height: '1em', margin: '0 0.4em' }} />
                    is working together with realtors in the Boulder area and beyond to develop{' '}
                    <strong>Commutably</strong>, the app that helps you estimate, visualize, and
                    compare your weekly driving time from different prospective homes or
                    neighborhoods.
                  </p>
                </div>

                <div className={css.appCard}>
                  <h3>Coming Soon: Backcountry Pulse</h3>
                  <p>
                    In the wilderness, emergency rescue is more often conducted by friends and
                    friendly strangers than by professional EMTs. Training and preparedness can mean
                    the difference between life and death, but most wilderness responders don't have
                    the benefit of everyday practice in the form of professional experience. In
                    austere and dangerous conditions, miles and hours from anywhere, rescuers need
                    every edge they can get.
                  </p>
                  <p>
                    <WildIdea style={{ display: 'inline', height: '1em', marginRight: '0.4em' }} />
                    is thrilled to announce a partnership with{' '}
                    <strong>
                      <a href="https://backcountrypulse.org/">Backcountry Pulse</a>
                    </strong>
                    , the premier provider of Wilderness Medicine training in the Front Range.
                    Together we are developing a branded app that{' '}
                    <strong>
                      delivers their world-class expertise into the hands of rescuers, wherever they
                      find themselves.
                    </strong>
                  </p>
                </div>
              </animated.div>
            ) : null
          )}

          {contentRightTransition((style, active) =>
            active ? (
              <animated.div style={style}>
                <div className={css.phoneDemoLayout}>
                  <div className={css.phoneDemoBlock}>
                    <PhoneFrame className={css.phone}>
                      <img src={onemapScreenshot} className={css.screenshot} />
                    </PhoneFrame>
                    <div className={css.phoneDemoCaption}>
                      <a href="https://dev.onemap.earth/user/hiebj" target="_blank" rel="nofollow">
                        OneMap
                      </a>
                    </div>
                  </div>
                  <div className={css.phoneDemoBlock}>
                    <PhoneFrame className={css.phone}>
                      <img src={commutablyScreenshot} className={css.screenshot} />
                    </PhoneFrame>
                    <div className={css.phoneDemoCaption}>
                      <a href="https://commutably.net" target="_blank" rel="nofollow">
                        Commutably
                      </a>
                    </div>
                  </div>
                </div>
              </animated.div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeRoute;
