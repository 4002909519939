import React from 'react';

export const LionBulb: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  'aria-label'?: string;
}> = ({ className, style, ...rest }) => (
  <svg
    viewBox="0 0 108.309 143.884"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    {...rest}
  >
    <g style={{ strokeWidth: '.8' }}>
      <path
        d="M59.88.405c-5.682.922-11.456 1.3-15.696 4.91-2.884 1.803-2.911 3.39-2.285 5.545.224 3.198-.683 5.742-4.608 6.205l-8.974 1.54c-3.709.575-6.625 2.489-8.968 4.996 8.518-.44-4.67 2.932-5.745 3.702C2.46 31.691 1.525 34.71 1.136 35.056c-1.256 1.654-.704 1.743.145 1.914l.805-1.035 1.44.339-.622 1.075.699.269c-.05.532-.31.923.486 1.07l.989.36c1.388.276.977.936.596 1.545-.606 1.135-.707 1.212-1.54 2.439-1.465 2.147-.707 3.242 1.01 3.884 3.054 3.005 7.419 4.451 12.184 5.79.766.324.588.1.063 1.193l-.604 1.527c-.247 1.018-.533 1.973-1.357 2.05-1.004.327-1.753 1.257-2.241.48-1.102-1.414-3.54-2.325-3.41-3.547-.077-1.362.144-.86-.191-2.093-.222-.844-.33-.886-.908-.146l-.364.713c-1.347 2.696.182 5.555 1.047 7.672.834 2.042 2.211 5.369 5.991 3.482 1.36-1.118 2.163-2.535 3.024-5.542 10.85-17.65 27.805-7.223 16.086 15.55-1.51 5.763.478 12.355 6.798 19.976-.513-1.573-1.04-3.055-.906-9.541.344-1.2.771-.92 1.23-.06 3.723 7.769 10.051 13.465 19.035 19.95l-.41-2.534c-3.143-9.247-5.545-18.418 1.145-26.647.675 8.411.81 16.515 8.5 28.935 2.096.607 4.124 1.254 10.137-.49.596-1.103 1.268-2.187 2.796-3.743 2.258-7.803 8.125-15.062 13.879-22.168 12.817-15.59 16.072-25.857-.413-42.845 3.776 1.724 7.553 4.108 11.33 7.475-3.765-14.134-16.695-18.69-30.106-22.745 8.018-1.182 16.196.455 24.577 5.075l-.776-1.3C91.105 15.953 80.1 9.946 65.473 7.906c-4.576.335-2.763-.21.09-1.748 2.584-1.48 13.178-.904 15.033-.952-.376-.234-9.3-2.139-10.5-2.006-4.25.218-8.247.989-13.059 1.084l.696-.656c-2.273.535-7.3 3.524-1.86-1 .09-.026.623-.885 4.53-2.096zm-.163 14.866c.216.005.434.013.654.026l2.344 9.745c.455 1.415.209 3.395-.019 3.912l-1.738 4.214c-1.884 3.669-4.448 1.283-5.556-1.889a163.073 163.073 0 01.174-15.113c1.213-.717 2.63-.923 4.141-.895zM30.14 24.966c1.805.032 3.8 1.277 5.85 2.86-.697.636-1.395 1.157-2.092 1.67-3.954.055-7.465 1.688-10.444 2.353.64-.858 2.035-2.615 2.452-3.53 1.199-2.463 2.642-3.38 4.234-3.353z"
        style={{ fill: 'currentColor' }}
      />
      <g style={{ fill: 'currentColor' }}>
        <path
          d="M42.131 118.677c.185.99.641.891 1.075.884 11.406-1.228 22.84-2.314 34.109-4.226.262-.383.14-.767-.048-1.15-.445-.51-.118-1.019-.02-1.529.493-1.29-.015-1.35-.045-1.354L66.46 112.7l-17.12 2.803c-2.052.232-4.077-.033-6.126-.082-1.494-.035-2.107.384-2.087.669zM41.813 126.243l.978 2.084c.363 1.103.997.717 1.575.639a468.518 468.518 0 0131.099-5.488c2.015-.268 2.46.127 2-2.213-.137-.73.575-1.903-2-1.361-6.919 1.26-13.136 2.02-19.272 2.723-4.845.322-9.348 1.071-13.742 1.957-.626.177-1.232.372-.638 1.659z"
          style={{ strokeWidth: '.529' }}
        />
        <path d="M43.797 135.433c-.813-1.066-.794-1.849.833-2.083l30.105-5.625c2.297-.254 1.905-.288 2.032 1.458l.11 1.287c.044 1.224-.409 1.791-1.1 1.838-.558.138-.76.568-.781 1.146-.251.546-.134.953 0 1.354.212.948-.123 1.446-.99 1.51-.701.202-1.238.5-1.354 1.043-.13.613-.578.687-1.198.468-.812.115-1.237.539-1.562 1.042l-.521.781-1.875-.625c-.823.973-.88 1.945-.782 2.917l-.937.052-.26.73c-.71.314-.857-.12-.99-.574-.518-.714-.876-.217-1.085.209-.226.404-.495 1.042-1.453 1.086l-.17-1.242-.623-1.038-.68 1.146-.263 1.164c-.912.042-1.815-.068-3.279-.075-2.003-.58-4.66-1.183-5.364-1.718a.849.849 0 01-.625-.625c.26-.263.455-.623.729-.938 0-.334.238-.735-.573-.833-.77-.143-1.074-.245-2.305-.388-.961-.017-2.471-.87-4.05-2.112z" />
      </g>
    </g>
  </svg>
);
