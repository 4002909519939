import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Toaster } from '@wildidea/components/Toaster/Toaster';
import { DeviceLocationProvider } from '@wildidea/components/hooks/useDeviceLocation';
import { BackStackProvider } from '@wildidea/components/hooks/useBackStack';

import WildIdeaTheme from '@theme/index.js';

import { App } from './App.js';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <BackStackProvider>
        <DeviceLocationProvider>
          <WildIdeaTheme>
            <Toaster defaultDismissTimeout={10000}>
              <App />
            </Toaster>
          </WildIdeaTheme>
        </DeviceLocationProvider>
      </BackStackProvider>
    </BrowserRouter>
  </React.StrictMode>
);
