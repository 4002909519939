// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yDT9A__phone {
  background: #191919;
  border-radius: 32px;
  padding: 1.5em;
}
.yDT9A__phone .EaMZ___phoneScreenGutter {
    position: relative;
    background: black;
    padding: 2px;
    border-radius: 12px;
    box-shadow: 0px 0px 6px 12px rgba(10, 10, 10, 0.8);
    /* inset -0.5px -0.5px #333; */
  }
.yDT9A__phone .EaMZ___phoneScreenGutter::before {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      top: 1em;
      width: 3em;
      height: 0.5em;
      border-radius: 16px;
      background: #111;
    }
.yDT9A__phone .aeXka__phoneScreen {
    aspect-ratio: 9 / 18.5;
    background: black;
    border-radius: 12px;
    overflow: hidden;
  }
`, "",{"version":3,"sources":["webpack://./src/components/PhoneFrame.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;AA8BhB;AA5BE;IACE,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,kDAAkD;IAClD,8BAA8B;EAchC;AAZE;MACE,cAAc;MACd,WAAW;MACX,kBAAkB;MAClB,SAAS;MACT,kCAAkC;MAClC,QAAQ;MACR,UAAU;MACV,aAAa;MACb,mBAAmB;MACnB,gBAAgB;IAClB;AAGF;IACE,sBAAsB;IACtB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;EAClB","sourcesContent":[".phone {\n  background: #191919;\n  border-radius: 32px;\n  padding: 1.5em;\n\n  & .phoneScreenGutter {\n    position: relative;\n    background: black;\n    padding: 2px;\n    border-radius: 12px;\n    box-shadow: 0px 0px 6px 12px rgba(10, 10, 10, 0.8);\n    /* inset -0.5px -0.5px #333; */\n\n    &::before {\n      display: block;\n      content: '';\n      position: absolute;\n      left: 50%;\n      transform: translate3d(-50%, 0, 0);\n      top: 1em;\n      width: 3em;\n      height: 0.5em;\n      border-radius: 16px;\n      background: #111;\n    }\n  }\n\n  & .phoneScreen {\n    aspect-ratio: 9 / 18.5;\n    background: black;\n    border-radius: 12px;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var phone = `yDT9A__phone`;
export var phoneScreenGutter = `EaMZ___phoneScreenGutter`;
export var phoneScreen = `aeXka__phoneScreen`;
export default ___CSS_LOADER_EXPORT___;
