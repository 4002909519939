import React from 'react';
import { Navigate, Route, useNavigate } from 'react-router';

import { AnimatedRoute, AnimatedRoutes } from '@wildidea/components/AnimatedRoutes/AnimatedRoutes';
import { useBodyClass } from '@wildidea/components/hooks/useBodyClass';
import { isTouchOnlyDevice } from '@wildidea/components/utils/isTouchOnlyDevice';

import HomeRoute from './routes/home/HomeRoute.js';

import './viewport.css';
import { LionBulb } from '@components/LionBulb.js';
import { WildIdea } from '@components/WildIdeaText.js';

export const App: React.FC<{}> = () => {
  useBodyClass(isTouchOnlyDevice() ? 'touch-only' : null);

  const hasVisitedBefore = window.localStorage.getItem(VISITED_BEFORE_STORAGE_KEY);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!hasVisitedBefore) {
      navigate('/about');
    }
    window.localStorage.setItem(VISITED_BEFORE_STORAGE_KEY, 'true');
  }, []);

  return (
    <>
      <header className="header">
        <WildIdea className="header-wild-text" />
        <LionBulb className="header-lionbulb" />
      </header>

      <main>
        <AnimatedRoutes>
          <AnimatedRoute path="/" key="home" element={<HomeRoute />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </AnimatedRoutes>
      </main>
    </>
  );
};

const VISITED_BEFORE_STORAGE_KEY = 'App/visited';
