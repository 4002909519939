import React from 'react';
import { config } from '@react-spring/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faCircle,
  faCircleDot,
  faLightbulbCflOn,
  faSquare
} from '@fortawesome/pro-light-svg-icons';
import { faClose, faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import { Theme } from '@wildidea/components/Theme/Theme';

import './variables.css';
import './reset.css';
import './typography.css';
import './components.css';

export const MOBILE_MODAL_TRANSITION = {
  from: {
    transform: 'translate3d(100vw, 0vh, 0) scale(1)'
  },
  enter: {
    transform: 'translate3d(0vw, 0vh, 0) scale(1)'
  },
  leave: {
    config: config.stiff,
    transform: 'translate3d(100vw, 0vh, 0) scale(1)'
  }
};

export const WINDOW_MODAL_TRANSITION = {
  from: {
    transform: 'translate3d(0vw, 40vh, 0) scale(0.6)',
    opacity: 0
  },
  enter: {
    transform: 'translate3d(0vw, 0vh, 0) scale(1)',
    opacity: 1
  },
  leave: {
    config: config.stiff,
    transform: 'translate3d(0vw, 50vh, 0) scale(0.6)',
    opacity: 0
  }
};

const WildIdeaTheme: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Theme
      className="wildidea"
      icons={{
        Checkbox: {
          checked: (
            <FontAwesomeIcon
              icon={faCheckSquare}
              style={{ color: 'var(--color-blue-1)' }}
              size="lg"
            />
          ),
          unchecked: (
            <FontAwesomeIcon icon={faSquare} style={{ color: 'var(--color-grey-2)' }} size="lg" />
          )
        },
        RadioInput: {
          checked: (
            <FontAwesomeIcon
              icon={faCircleDot}
              style={{ color: 'var(--color-blue-1)' }}
              size="lg"
            />
          ),
          unchecked: (
            <FontAwesomeIcon icon={faCircle} style={{ color: 'var(--color-grey-2)' }} size="lg" />
          )
        },
        Alert: {
          info: <FontAwesomeIcon icon={faLightbulbCflOn} size="2x" />
        },
        Modal: {
          close: (
            <FontAwesomeIcon icon={faClose} size="xl" style={{ color: 'var(--color-purple-1)' }} />
          ),
          mobileClose: (
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="lg"
              style={{ color: 'var(--color-purple-1)', marginBottom: '-0.1em' }}
            />
          )
        }
      }}
      animations={{
        Modal: {
          window: WINDOW_MODAL_TRANSITION,
          mobile: MOBILE_MODAL_TRANSITION
        }
      }}
    >
      {children}
    </Theme>
  );
};

export default WildIdeaTheme;
